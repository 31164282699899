import React from 'react'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import NetlifyForm from 'react-netlify-form'


const ModalExamplePage = () => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div>

<NetlifyForm id="card_order" name='Contact Form'>
  {({ loading, error, success }) => (
    <div className="order-form">
      {loading &&
        <div>Loading...</div>
      }
      {error &&
        <div>Your information was not sent. Please try again later.</div>
      }
      {success &&
<div class="success-pop">Thank you for your order! <br/>We will send payment information to your email in couple of hours. <br/>Contact us if you have any questions! <br/> Dagcoin Gift Card team.</div>
      }
      {!loading && !success &&
      <React.Fragment>
    <h3> Order giftcard</h3>
  <div className="form-split">
          <p>
            <label>Card value in DAGs <select name="cardvalue[]" required>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
            </select></label>
          </p>
          <p>
            <label>Quantity: <input type="number" name="quantity" min="1" max="50" required/></label>
          </p>
          <p>
            <label>First name: <input type="text" name="firstname" required/></label>   
          </p>
          <p>
            <label>Last name: <input type="text" name="lastname" required/></label>   
          </p>
          <p>
            <label>Email: <input type="email" name="email" required/></label>
          </p>
    </div>
    <div className="form-split">
          <p>
            <label>Phone: <input type="number" name="phone" required/></label>
          </p>
          <p>
            <label>Address: <input type="text" name="address" required/></label>
          </p>
          <p>
            <label>City: <input type="text" name="city" required/></label>
          </p>
          <p>
            <label>Country/region: <input type="text" name="country_region" required/></label>
          </p>
          <p>
            <label>Postal code: <input type="number" name="postal_code" required/></label>
          </p>
    </div>
    <div className="form-footer">
        <p class="payment-information">Possible payment methods: Bank transfer, BTC, Card payment.</p>
          <p>
            <button className="send-order" type="submit">ORDER</button>
          </p>
     </div>
     </React.Fragment>
      }
    </div>
  )}
</NetlifyForm>
                
                


      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default ModalExamplePage